<template>
  <div class="select-entreprise-page">
    <div class="block-left">
      <img src="@/assets/img/man.webp" class="bg-img" alt="agent immobilier" />
      <div class="left-txt">
        <img src="@/assets/img/logo-m0.png" alt="logo maisonier" />
        <div>
          <span class="title">
            Votre outil idéal pour une gestion collaborative rentable.
          </span>
          <span class="help">
            Vous rencontrez un problème ? <a href="#">Contactez-nous</a>
          </span>
        </div>
      </div>
    </div>
    <div class="block-right">
      <b-overlay
        :show="showOverlay1"
        v-if="!entreprises.length"
        class="no-company"
      >
        <span class="title"> Bienvenu sur Maisonier </span>
        <span class="desc">
          Nous sommes heureux de vous compter parmis nous! <br />
          <b>Votre compte</b> n'est associé à aucune entreprise pour le moment.
          Vous avez deux options qui s'offrent à vous !
        </span>
        <div class="option-container">
          <div class="option-item">
            <div class="icon-part">
              <img src="@/assets/img/creer.png" alt="créer une entreprise" />
            </div>
            <div class="option-txt">
              <span class="title"> Créer une entreprise </span>
              <p>
                Si vous êtes un promoteur immobilier indépendant et que vous ne
                souhaitiez pas rejoindre une entreprise déjà créée.
              </p>
            </div>
            <button @click="modalVisible = true">Je crée une entreprise</button>
          </div>
          <div class="option-item" :key="cle">
            <div class="icon-part">
              <img
                src="@/assets/img/ajouter.png"
                alt="rejoindre une entreprise"
              />
            </div>
            <div class="option-txt">
              <span class="title"> Rejoindre une entreprise existante </span>
              <p>
                Si vous travaillez au compte d'une entreprise, vous devez
                envoyer le code suivant à votre reponsable pour qu'il vous
                ajoute. <br />
                Code :
                <b style="color: #f5365c">{{ user.code }}</b>
              </p>
            </div>
            <button @click="copyText(user.code)" :class="{ copyBtn: copyBtn }">
              Copier le code
            </button>
          </div>
        </div>
      </b-overlay>
      <b-overlay :show="showOverlay2" v-else class="no-company has-company">
        <span class="title"> Bienvenu sur Maisonier </span>
        <span class="desc">
          Veuillez selectionnez l'entreprise avec laquelle vous aimeriez
          travailler aujourd'hui.
        </span>
        <div class="option-container has-company">
          <div
            class="option-item has-company"
            v-for="entreprise in entreprises"
            :key="entreprise.idEntreprise"
          >
            <div class="icon-part">
              <img :src="entreprise.logo" alt="logo entreprise" />
            </div>
            <div class="option-txt">
              <span class="title">
                {{ entreprise.nom }}
              </span>
              <p>Mon poste : {{ entreprise.Employes.poste }}</p>
            </div>
            <button @click.prevent="useEnterprise(entreprise)">
              Je me connecte
            </button>
          </div>
          <div class="option-item has-company">
            <div class="icon-part">
              <img
                src="@/assets/img/creer.png"
                style="object-fit: contain"
                alt="rejoindre une entreprise"
              />
            </div>
            <div class="option-txt">
              <span class="title"> Ajouter une entreprise </span>
              <p>
                Vous pouvez créer une autre entreprise à votre compte. Créer la
                ou rejoignez une autre entreprise existante.
              </p>
            </div>
            <button @click="modalVisible = true">Ajouter une entreprise</button>
          </div>
          <div class="option-item" :key="cle">
            <div class="icon-part">
              <img
                src="@/assets/img/ajouter.png"
                alt="rejoindre une entreprise"
              />
            </div>
            <div class="option-txt">
              <span class="title"> Rejoindre une entreprise existante </span>
              <p>
                Si vous travaillez au compte d'une entreprise, vous devez
                envoyer le code suivant à votre reponsable pour qu'il vous
                ajoute. <br />
                Code :
                <b style="color: #f5365c">{{ user.code }}</b>
              </p>
            </div>
            <button @click="copyText(user.code)" :class="{ copyBtn: copyBtn }">
              Copier le code
            </button>
          </div>
        </div>
      </b-overlay>
    </div>
    <!-- modal pour les informations du propriotaire -->
    <div class="create-enterprise-modal" :class="{ visible: modalVisible }">
      <div class="create-txt">
        <span class="title"> Ajouter une entreprise </span>
        <span class="desc">
          Renseignez les informations de bases qui constituent votre entreprise
        </span>
        <section class="create-txt-main">
          <span class="title">Informations sociales</span>
          <div class="data-entry-container">
            <label>
              <span>Nom</span>
              <input
                type="text"
                placeholder="Nom de votre entreprise"
                v-model="form.nom"
              />
            </label>
            <!-- <span>Numéro de téléphone</span> -->
            <label>
              <span>Numéro de téléphone</span>
              <input
                type="tel"
                placeholder="Numéro de téléphone"
                v-model="form.tel"
              />
            </label>
          </div>
          <section>
            <!-- <span class="title">Informations légales</span> -->
            <div class="data-entry-container">
              <label>
                <span>Raison social</span>
                <input
                  type="text"
                  placeholder="Raison social"
                  v-model="form.raisonSociale"
                />
              </label>
              <label>
                <span>Siège social</span>
                <input
                  type="text"
                  placeholder="Siège social"
                  v-model="form.siegeSocial"
                />
              </label>
              <label>
                <span>Registre de commerce</span>
                <input
                  type="text"
                  placeholder="Registre de commerce"
                  v-model="form.registreCommerce"
                />
              </label>
              <label>
                <span>Adresse email</span>
                <input
                  type="email"
                  placeholder="Adresse email"
                  v-model="form.email"
                />
              </label>
            </div>
          </section>
          <section>
            <span class="title">Ajouter le logo de votre entreprise</span>
            <img-inputer
              class="entry-img"
              @onChange="loadLogo"
              placeholder=""
              theme="light"
              size="xl"
              bottom-text=""
              icon="img"
            />
          </section>
          <section class="last">
            <div @click="proprio = true">
              <!-- <input type="checkbox"> -->
              <b style="cursor: pointer">
                Ajouter les informations du propriétaire
              </b>
            </div>
            <button @click="submitForm()" style="padding-left: 80px; padding-right: 80px; height: 50px;display: flex;justify-content: center;align-items: center;" >
              <div v-if="loading" class="loader"></div>
              <div v-else>Créer entreprise</div>
            </button>
          </section>
        </section>
      </div>
      <div class="create-img">
        <img src="@/assets/img/promoteur-maisonier.webp" alt="" />
        <div class="content">
          <span class="title"> A vous la liberté </span>
          <span class="desc"
            >Configurer vos informations une fois pour toute et changer votre
            façon de travailler</span
          >
        </div>
      </div>
      <img
        src="@/assets/img/close.png"
        alt="close modal icon"
        class="close-modal"
        @click="modalVisible = false"
      />
    </div>
    <!-- modal pour les informations du propriotaire -->
    <div class="proprio-modal" v-show="proprio">
      <div class="proprio-main">
        <i class="ik ik-x clos" @click="proprio = false"></i>
        <div class="proprio-header">
          <span>Renseigner les informations du propriétaire</span>
        </div>
        <div class="proprio-body">
          <section>
            <span class="title">Informations sociales</span>
            <div class="data-entry-container">
              <span>Nom</span>
              <label>
                <input
                  type="text"
                  placeholder="Nom"
                  v-model="form.nomProprietaire"
                />
              </label>
              <span>Prénom</span>
              <label>
                <input
                  type="tel"
                  placeholder="Numéro de téléphone"
                  v-model="form.telProprietaire"
                />
              </label>
              <span>Email</span>
              <label>
                <input
                  type="email"
                  placeholder="E-mail"
                  v-model="form.emailProprietaire"
                />
              </label>
            </div>
          </section>
          <section class="">
            <span class="title">Photo Carte d'identité du propriétaire</span>
            <div class="data-entry-container cni">
              <img-inputer
                class="entry-img"
                @onChange="loadCniAvant"
                placeholder=""
                theme="light"
                size="xl"
                bottom-text=""
                icon="img"
              />
              <img-inputer
                class="entry-img"
                @onChange="loadCniArriere"
                placeholder=""
                theme="light"
                size="xl"
                bottom-text=""
                icon="img"
              />
            </div>
          </section>
          <button @click="proprio = false">Valider</button>
        </div>
      </div>
    </div>
    <!-- user profile -->
    <!-- <div class="user-profil" title="Mon profil" @click="logout()"> -->
    <div class="user-profil" title="Mon profil">
      <img :src="avatar" :alt="user.nom" />
    </div>
    <b-button
      class="position-fixed validate btn-icon"
      variant="danger"
      @click.prevent="logout"
      v-b-tooltip.left="'Se deconnecter'"
      ><i class="fa fa-power-off fa-lg"></i
    ></b-button>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapGetters } from "vuex";
const php = require("phpjs");

export default {
  name: "subscribe",
  components: {
    FormWizard,
    TabContent,
  },
  data: () => ({
    loading: false,
    form: {
      raisonSociale: "",
      registreCommerce: "",
      email: "",
      tel: "",
      nom: "",
      logo: "",
      nomProprietaire: "",
      telProprietaire: "",
      emailProprietaire: "",
      cniAvant: "",
      cniArriere: "",
    },
    error: { nom: false, tel: false },
    loadingWizard: false,
    check: true,
    addProprioData: false,
    etat: 2,
    modalVisible: false,
    copyBtn: false,
    proprio: false,
    showOverlay1: false,
    showOverlay2: false,
    entreprise: {},
    avatar: "",
  }),
  computed: {
    ...mapGetters(["user"]),
    entreprises() {
      return this.user.entreprises || [];
    },
    reason() {
      return this.$route.query.reason;
    },
    selected_enterprise() {
      return this.entreprises.find(
        (elt) => elt.idEntreprise == storage.get("selected_enterprise")
      );
    },
  },
  mounted() {
    this.avatar =
      this.user.avatar || "http://localhost:8080/img/profile-picture.jpg";
    console.log(this.user);
  },

  methods: {
    setLoading: function (value) {
      this.loadingWizard = value;
    },
    handleValidation: function (isValid, tabIndex) {
      console.log("Tab: " + tabIndex + " valid: " + isValid);
      this.indexForm = this.indexForm + 1;
      console.log("indexFom: ", this.indexForm);
    },
    async copyText(code) {
      try {
        await navigator.clipboard.writeText(code);
        this.copyBtn = true;
        alert("Le code a été copié avec succès !");
      } catch ($e) {
        alert("Cannot copy");
      }
    },
    validateFirst() {
      return new Promise((resolve, reject) => {
        /*if(!this.form.nom || this.form.tel ){
							this.check=false
							if(!this.form.nom ){ 
								this.error.nom=true}
							else{this.error.nom=false;}
							if (!this.form.tel) {
							this.error.tel=true}
							else{this.error.tel=false;}
				}else{
						this.check=true
				}*/
        setTimeout(() => {
          resolve(this.check);
        }, 1000);
      });
    },
    useEnterprise(entreprise) {
      this.entreprise = entreprise;
      console.log(this.entreprise);
      this.$bvModal.show("modal-switch-enterprise");
      storage.set("selected_enterprise", entreprise.idEntreprise);

      let privileges = [];
      for (const role of entreprise.Employes.roles) {
        privileges = [...privileges, ...role.privileges];
      }
      this.$store.dispatch("permissions", privileges);
      this.showOverlay2 = true;
      setTimeout(() => {
        window.location.href = this.$router.options.base;
        // this.router.push({ name: 'home'})
      }, 3000);
    },

    logout() {
      this.$store.dispatch("user", {});
      storage.clear();
      this.$router.push({ name: "login" });
    },

    submitForm(e) {
      this.loading = true;
      /*e.preventDefault()
			let error = false 
			for (const key in this.error) {
				this.error[key] = php.empty(this.form[key])
				if (this.error[key]) {
					error = true
				}
			}
			if (error) {
				return 
			}*/
      axios
        .post("entreprises", this.form)
        .then((response) => {
          this.loading = false
          App.notifySuccess(response.message);
          let user = this.user;
          user.entreprises = [...user.entreprises, response.result];
          this.$store.dispatch("user", user);
          storage.set("user", user);
          storage.set("selected_enterprise", response.result.idEntreprise);
          this.$router.push({ name: "subscribe" });
        })
        .catch((error) => {
          this.loading = false
          App.alertError(error.message);
        });
    },
    async loadLogo(file) {
      this.form.logo = await this.$getBase64(file);
    },
    async loadCniAvant(file) {
      this.form.cniAvant = await this.$getBase64(file);
    },
    async loadCniArriere(file) {
      this.form.cniArriere = await this.$getBase64(file);
    },
  },
};
</script>


<style scoped>
* {
  box-sizing: border-box;
}
button.validate {
  bottom: 1em;
  right: 1em;
  width: 3em;
  height: 3em;
  box-shadow: 3px 1px 1px gray;
}

.leloader,
.leloader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.leloader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #e74c3c;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.select-entreprise-page {
  display: flex;
  height: 100vh;
  width: 100%;
  background: #f8f8f8;
}
.select-entreprise-page .block-left {
  position: relative;
  height: 100%;
  width: 35%;
}
.select-entreprise-page .block-right {
  padding: 2% 5%;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 65%;
  overflow-y: auto;
}
.block-left .bg-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.block-left .left-txt {
  padding: 50px 5% 20px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}
.left-txt img {
  width: 50px;
}
.left-txt div {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}
.left-txt .title {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  /* text-align: left; */
}
.left-txt .help {
  margin-top: 10rem;
  font-size: 0.85rem;
  color: white;
  /* text-align: left; */
}
.left-txt .help a {
  text-decoration: underline;
  color: white;
  font-weight: bold;
}
.block-right .no-company {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.no-company .title,
.create-txt .title {
  font-size: 2rem;
  font-weight: bold;
  color: #191c22;
}
.no-company .desc,
.create-txt .desc {
  margin-top: 1rem;
  width: 60%;
  color: #747474;
  font-size: 1rem;
  line-height: 1.8rem;
}
.option-container {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  row-gap: 15px;
  width: 100%;
  height: 60%;
}
.option-container .option-item {
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 25rem;
  background: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border-radius: 8px;
  /* cursor: pointer; */
}
.option-item:hover {
  transform: scale(1.03);
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.08);
}
.option-item .icon-part {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
}
.icon-part img {
  width: 90px;
  height: 90px;
}
.option-item button {
  margin-left: 5%;
  width: 90%;
  height: 50px;
  border-radius: 8px;
  background: transparent;
  border: 1px solid #191c22;
  color: #191c22;
  font-weight: bold;
  transition: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.option-item button:hover {
  background: #191c22;
  color: white;
}
.option-txt {
  padding: 5%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 180px);
}
.option-txt .title {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  text-align: justify;
}
.option-txt p {
  text-align: justify;
}
.no-company.has-company {
  height: auto;
}
.option-item.has-company .icon-part {
  height: 50%;
  border-radius: 15px 15px 0 0;
}
.option-item.has-company .icon-part img {
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0 0;
  object-fit: cover;
}
.option-item.has-company .option-txt {
  height: calc(100% - 50px - 50%);
}
.option-item.has-company button {
  margin-left: 5%;
  width: 90%;
}
.create-enterprise-modal {
  position: fixed;
  top: 0;
  left: -130vw;
  display: flex;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  transition: 1s ease;
  z-index: 10;
}
.create-enterprise-modal.visible {
  left: 0vw;
}
.create-enterprise-modal .close-modal {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
  cursor: pointer;
}
.user-profil {
  position: fixed;
  top: 30px;
  right: 30px;
  display: flex;
  width: 60px;
  height: 60px;
  cursor: pointer;
  border-radius: 50%;
}
.user-profil img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.create-enterprise-modal .create-img {
  position: relative;
  height: 100%;
  width: 40%;
}
.create-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.create-img .content {
  padding: 5%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}
.create-img .content .title {
  color: white;
  line-height: 2.5rem;
  font-size: 1.5rem;
  font-weight: bold;
}
.create-img .content span {
  color: white;
  line-height: 2rem;
  font-size: 1.2rem;
}
.create-enterprise-modal .create-txt {
  padding: 2% 10%;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 60%;
  background: white;
}
.create-txt .create-txt-main {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.create-txt-main section {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.create-txt-main section .title {
  padding-bottom: 5px;
  margin-bottom: 15px;
  width: 100%;
  border-bottom: 1px solid #efefef;
  font-size: 1rem;
}
.create-txt-main section .data-entry-container {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.data-entry-container label {
  height: 100px;
  width: 50%;
  border-radius: 10px;
  /* border: 1px solid #efefef; */
}
.data-entry-container label input {
  padding-left: 10px;
  height: 50%;
  width: 90%;
  border-radius: 10px;
  border: 1px solid #efefef;
  margin-top: 5px;
}
.data-entry-container label span {
  padding: 5px; 
  font-size: 1.2em;
}
button.copyBtn {
  border-color: #f5365c;
  color: #f5365c;
}
.entry-img {
  width: 8rem;
  height: 8rem;
}
section.last {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
section.last button {
  padding: 15px 25px;
  border: none;
  width: auto;
  background: #f5365c;
  color: white;
  font-weight: bold;
  border-radius: 8px;
}
.proprio-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.08);
  z-index: 10;
}
.proprio-modal .proprio-main {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 80%;
  border-radius: 8px;
  background: white;
}
.proprio-main i {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.3rem;
  cursor: pointer;
}
.proprio-main .proprio-header {
  margin-bottom: 30px;
  padding: 0 5%;
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  border-bottom: 1px solid #eee;
}
.proprio-header span {
  font-weight: 600;
  font-size: 1.2rem;
}
.proprio-main .proprio-body {
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 160px);
}
.proprio-body section {
  margin-bottom: 20px;
}
.proprio-body section .title {
  font-weight: 500;
  font-size: 1rem;
}
.proprio-body section .data-entry-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.proprio-body section .data-entry-container.cni {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.proprio-body section .data-entry-container label {
  height: 50px;
  width: 100%;
}
.proprio-body button {
  margin-top: 20px;
  width: 100%;
  height: 45px;
  border-radius: 4px;
  background: transparent;
  color: white;
  font-weight: bold;
  border: none;
  border: 1px solid #191c22;
  color: #191c22;
  cursor: pointer;
}
@media only screen and (max-width: 1200px) {
  .option-container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    height: auto;
  }
}
@media only screen and (max-width: 800px) {
  .select-entreprise-page {
    flex-direction: column;
    height: auto;
  }
  .select-entreprise-page .block-left {
    width: 100%;
    height: 20rem;
  }
  .select-entreprise-page .block-right {
    height: auto;
  }
  .block-left .left-txt {
    padding: 0px 5% 10px;
    width: 100%;
    height: 100%;
  }
  .left-txt .title {
    font-size: 1.5rem;
  }
  .left-txt .help {
    margin-top: 2.5rem;
  }
  .select-entreprise-page .block-right {
    padding: 2% 5%;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    overflow-y: auto;
  }
  .no-company.has-company .title,
  .no-company.has-company .desc {
    display: none;
  }
  .option-container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    height: auto;
  }
  .option-container .option-item {
    padding: 1% 2%;
    height: auto;
  }
  .option-item.has-company {
    padding: 0px 0 2%;
  }
  .no-company .title {
    font-size: 1.5rem;
  }
  .no-company .desc {
    margin-top: 1rem;
    width: 100%;
    text-align: justify;
    font-size: 1rem;
    line-height: 1.8rem;
  }
  .option-txt .title {
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  .left-txt img {
    margin-top: 0.5rem;
    width: 40px;
  }
}
.label-style {
  display: flex;
  justify-content: center;
  align-items: left; 
  flex-direction: column; 
  width: 60%;
}
.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 10px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
.colors2 {
  border-bottom: 16px solid transparent;
}
.colors3 {
  border-bottom: 16px solid red;
  border-right: 16px solid green;
}
.colors4 {
  border-bottom: 16px solid red;
  border-right: 16px solid green;
  border-left: 16px solid pink;
}
</style>